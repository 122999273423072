/* You can add global styles to this file, and also import other style files */

// Icons
@import url("https://i.icomoon.io/public/5287269068/ngx-feng/style.css");

// Fonts
// Titillium Web
@font-face {
  font-family: "Titillium Web Black";
  src: url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/titillium-web.black.ttf") format("truetype"),
    url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/titillium-web.black.woff") format("woff"),
    url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/titillium-web.black.woff2") format("woff2");
}

@font-face {
    font-family: Heebo;
    src: url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/Heebo-Medium.ttf") format("truetype");
}
@font-face {
    font-family: ApexMk3;
    src: url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/Apex+Mk3-Medium.otf") format("truetype");
}
@font-face {
    font-family: ApexMk3-ExtraLight;
    src: url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/Apex+Mk3-ExtraLight.otf") format("truetype");
}
@font-face {
    font-family: CityDMed;
    src: url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/CityDMed.ttf") format("truetype");
  }
  @font-face {
    font-family: CityDBol;
    src: url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/CityDBol.ttf") format("truetype");
  }
@font-face {
    font-family: GillSans;
    src: url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/GillSans.otf") format("truetype");
}
@font-face {
    font-family: GillSansBold;
    src: url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/GillSans-Bold.ttf") format("truetype");
}
@font-face {
    font-family: GillSansMT;
    src: url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/GillSans-MT.ttf") format("truetype");
}
@font-face {
    font-family: GillSansMTBold;
    src: url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/GillSans-MT-Bold.ttf") format("truetype");
    font-style: bold;
    font-weight: 700;
}
@font-face {
    font-family: GillSansMTItalic;
    src: url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/GillSans-MT-Italic.ttf") format("truetype");
}
@font-face {
    font-family: GillSansMTItalic;
    src: url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/GillSans-MT-Italic.ttf") format("truetype");
}

// Barlow
@font-face {
    font-family: BarlowSemiCondensedBold;
    src: url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/Barlow Semi Condensed-Bold.ttf") format("truetype");
}
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&family=Barlow+Semi+Condensed:wght@400;700&family=Titillium+Web:wght@200;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

// Angular Material Theme
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '@angular/material/prebuilt-themes/indigo-pink.css';

// Libs imports always last
@import '~@fengbrasil/ngx-fengst-ui/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-auth/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-layout/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-home/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-events/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-account/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-exclusive-content/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-contact/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-partner/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-experience/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-checkout/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-xray/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-donation/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-gift/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-security/assets/dist/styles';


.fengstapp__whatsapp {

    // &:hover {
    //     .fengstapp__whatsapp-open {
    //         right: -40px;

    //         @include respond-to('medium') {
    //             right: 0;
    //         }
    //     }
    // }

    &-open {
        position: fixed;
        bottom: 90px;
        z-index: 9999;

        transition: 1s;
        right: -206px;

        @include respond-to('medium') {
            right: -190px;
        }

        > img {
            width: 86%;

            @include respond-to('medium') {
                width: 100%;
            }
        }

    }
  }
